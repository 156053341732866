import React from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import salary1 from "../../assets/salary1.png";
import salary2 from "../../assets/salary2.png";
import salary3 from "../../assets/salary3.png";
import salary4 from "../../assets/salary4.png";

import news from "../../assets/news5.png";

import forbes from "../../assets/forbes.png";
import exame from "../../assets/exame.png";
import tecmundo from "../../assets/tecmundo.png";
import folha from "../../assets/folha_g.png";

import quote from "../../assets/quote.png";

import ActiveCampaignForm from "../ActiveCampaignForm";

import {
  Container,
  Content,
  KenzieMidia,
  AboutUs,
  Section,
  Box,
} from "./styles";

const PaymentSection = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <Container>
      <Content>
        <h3>Emprego em alta para devs</h3>

        <div className="news">
          <img src={news} alt="" />
        </div>

        <h3>Como o mercado remunera um DEV?</h3>

        <div className="salary-box">
          <img src={salary1} alt="Notícia" />
          <img src={salary2} alt="Notícia" />
          <img src={salary3} alt="Notícia" />
          <img src={salary4} alt="Notícia" />
        </div>

        <h3>A Kenzie transforma você em um(a) DEV front end mesmo que:</h3>

        <div className="difficulties">
          <div className="difficulty">
            <p>#1</p>
            <span>Não tenha conhecimento prévio.</span>
          </div>
          <div className="difficulty">
            <p>#2</p>
            <span>
              Não seja formado em nenhum curso de graduação ou técnico.
            </span>
          </div>
          <div className="difficulty">
            <p>#3</p>
            <span>Não saiba por onde começar.</span>
          </div>
          <div className="difficulty">
            <p>#4</p>
            <span>Não saiba falar inglês.</span>
          </div>
          <div className="difficulty">
            <p>#5</p>
            <span>Não tenha recursos para investir.</span>
          </div>
        </div>

        <KenzieMidia>
          <p>Kenzie na mídia</p>

          <Section>
            <Carousel
              responsive={responsive}
              draggable={true}
              showDots={true}
              arrows={true}
              renderButtonGroupOutside={true}
            >
              <Box>
                <img src={forbes} alt="Forbes" />
              </Box>

              <Box>
                <img src={exame} alt="Exame" />
              </Box>

              <Box>
                <img src={tecmundo} alt="Tecmundo" />
              </Box>

              <Box>
                <img src={folha} alt="Folha" />
              </Box>
            </Carousel>
          </Section>
        </KenzieMidia>

        <h3>Sobre nós</h3>
        <AboutUs>
          <dib className="about">
            <img src={quote} alt="Quote" />
            <h4>
              "Não seria ótimo se você tivesse uma escola que não cobrasse
              mensalidade até você conseguir um emprego e que realmente te
              deixasse pronto para o mercado de trabalho. E tudo isso em menos
              da metade do tempo de uma faculdade tradicional?"
            </h4>

            <p>
              A Kenzie Academy é uma escola de programação de origem
              norte-americana, que transforma vidas através da educação e
              tecnologia. Essa frase resume nosso propósito e como queremos,
              cada vez mais, acelerar carreiras na área de tecnologia,
              oferecendo um ensino de qualidade, atualizado com o que mercado
              pede, tanto em hard, quanto em soft skills. Um curso, com um
              método validado por centenas de estudantes e acessível mesmo para
              quem não tem recursos para investir agora ou não possui
              conhecimento prévio em programação.
            </p>
          </dib>
        </AboutUs>

        <div id="form">
          <ActiveCampaignForm formId />
        </div>
      </Content>
    </Container>
  );
};

export default PaymentSection;
