import styled from "styled-components";

export const Container = styled.div`
  padding: 1rem 0;
  width: 100%;
  margin: 0 auto;
  background-color: var(--gray-800);
  color: var(--white);
  text-align: center;

  h3 {
    text-align: center;
    margin: 3rem auto;
    width: 80%;
    max-width: 42.875rem;
    font-size: 1.625rem;
    line-height: 2.5rem;

    @media (min-width: 1024px) {
      margin: 4rem auto;
      font-size: 2.625rem;
      line-height: 3.5rem;
      text-align: center;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;

  div.news {
    width: 80%;
    margin: 0 auto;

    img {
      width: 100%;
      border-radius: 0.9rem;
      box-shadow: 0 0 1px 2px #35353545;
    }
  }

  div.salary-box {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 1024px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1.5rem;
      margin-bottom: 8rem;
    }

    img {
      width: 100%;
      margin-bottom: 2rem;

      @media (min-width: 1024px) {
        margin: 0;
      }
    }
  }

  div.difficulties {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 0 auto;

    @media (min-width: 1024px) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

    div.difficulty {
      width: 100%;
      background-color: var(--gray-700);
      margin-bottom: 2rem;
      padding: 2rem;
      text-align: left;
      border-radius: 0.9rem;
      box-shadow: 0 1px 1px 2px var(--gray-700);

      @media (min-width: 1024px) {
        width: 28rem;
        margin: 2rem;
        background-color: transparent;
        box-shadow: none;
        padding: 0;
      }

      p {
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-size: 4rem;
        font-weight: bold;
        color: var(--blue-500);
      }

      span {
        line-height: 1.5rem;
      }
    }
  }

  div#form {
    width: 80%;
    margin: 0 auto;
  }
`;

export const Section = styled.div`
  box-sizing: border-box;
  width: 100%;

  padding: 2rem;

  /* .react-multi-carousel-list button {
    display: none;
  } */
`;

export const Box = styled.div`
  box-sizing: border-box;
  width: 100%;

  border-radius: 0.3em;

  margin: 1.5rem 0;
  color: #fff;
  line-height: 1.7em;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 1024px) {
    padding: 2rem;
  }

  img {
    width: 100%;
    height: 100%;

    @media (min-width: 1024px) {
      width: 500px;
      height: 460px;
    }
  }
`;

export const KenzieMidia = styled.div`
  width: 100%;
  margin: 8rem auto;
  margin-top: 8rem;
  padding: 2rem;
  border-radius: 0.9rem;

  p {
    color: var(--white);
    font-size: 2.265rem;
    font-weight: bold;
  }

  div.midia-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 1024px) {
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1rem;
    }

    img {
      width: 100%;
      margin: 1rem 0;

      @media (min-width: 1024px) {
        width: 100%;
      }
    }
  }
`;

export const AboutUs = styled.div`
  width: 80%;
  max-width: 1300px;
  margin: 0 auto;
  border-radius: 0.9rem;
  border: 1px solid #707070;
  padding: 3rem;
  position: relative;
  text-align: left;
  margin-top: 8rem;

  h4 {
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 2rem;

    @media (min-width: 1024px) {
      font-size: 1.75rem;
    }
  }

  p {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  img {
    width: 7.8rem;
    position: absolute;
    top: -3.9rem;
    right: calc(50% - 3.9rem);
  }
`;
