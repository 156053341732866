import styled from "styled-components";

export const Container = styled.section`
  padding: 1rem 0;
  width: 100%;
  margin: 0 auto;
  background-color: var(--black-500);

  h2 {
    font-size: 2rem;
    color: var(--white);
    text-align: center;
  }
`;

export const Content = styled.div`
  width: 80%;
  margin: 0 auto;
  background-color: var(--black-500);
  color: var(--white);
  text-align: center;
  padding: 4rem 0;

  h3 {
    font-size: 1.625rem;
    line-height: 2.5rem;

    @media (min-width: 1024px) {
      margin: 2 auto;
      font-size: 3rem;
      line-height: 3.5rem;
      text-align: center;
    }
  }
`;

export const B2B = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  background: linear-gradient(90deg, #001122 0%, #002b58 100%);
  border-radius: 0.9rem;
  padding: 2rem;

  @media (min-width: 1024px) {
    padding: 4rem 5rem;
    border-radius: 0.9375rem;
    border-radius: 2.5rem;
  }

  p {
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 2rem;
    border-bottom: 1px solid var(--gray-600);

    @media (min-width: 1024px) {
      font-size: 3.25rem;
      margin-top: 0;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 3rem;
    }

    img {
      width: 80%;
      margin: 1rem auto;

      @media (min-width: 1024px) {
        width: 13.625rem;
      }
    }
  }
`;

export const DanielMessage = styled.div`
  width: 100%;
  padding: 4rem 0;
  margin: 0 auto;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 30% 70%;
    padding: 8rem 0;
  }

  div.image-box {
    width: 100%;

    img {
      width: 100%;
    }

    p {
      font-size: 1rem;
      letter-spacing: 1px;
      line-height: 2rem;

      span {
        color: var(--blue-500);
      }
    }
  }

  div.text-box {
    @media (min-width: 1024px) {
      height: 100%;
      padding-left: 3rem;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }

    p {
      font-size: 1.2rem;
      line-height: 1.8rem;
      text-align: left;

      @media (min-width: 1024px) {
        font-size: 1.5rem;
        line-height: 2.5rem;
      }

      span {
        color: var(--blue-500);
      }
    }
  }
`;
