import React from "react";

import Comment from "../Comment";

import codeBLur from "../../assets/code-blur.png";
import codeBox from "../../assets/code-box.png";
import laptop from "../../assets/laptop.png";

import {
  Container,
  Section,
  ResultsWrapper,
  ResultsContent,
  PlayIcon,
} from "./styles";

const CommentsSection = () => {
  const commentsData = [
    {
      name: "Ingrid Benites",
      comment:
        "Aprendi programação do zero com a Kenzie e é incrível como em apenas 6 meses eu já consegui um emprego na área como desenvolvedora web e trabalhando com algo que eu sempre sonhei. E melhor ainda, contratada por uma empresa dos Estados Unidos. Aqui as opotunidades são reais!",
      photo: "https://kenzie.com.br/images/ingrid.png",
      job: "Desenvolvedora",
    },
    {
      name: "Estevão Kalebe Alves",
      comment:
        "Eu até tinha começado uma faculdade na área de tecnologia, mas lá eu praticamente não aprendi programação. Na Kenzie, o time de ensino me instruiu a aprender programação de verdade e antes mesmo de concluir o curso, consegui minha vaga no mercado com apenas 7 meses de Kenzie.",
      photo: "https://kenzie.com.br/images/estevao.jpg",
      job: "Desenvolvedor",
    },
    {
      name: "Augusto Pietroski",
      comment:
        "Conquistar minha primeira vaga no mercado internacional, com apenas 9 meses de curso em uma empresa dos Estados Unidos foi algo diferenciado. A Kenzie me deu os fundamentos para que eu conquistasse essa vaga. Quando ela diz que prepara o profissional para o mercado, ela não está brincando!",
      photo: "https://kenzie.com.br/images/carous7-img.png",
      job: "Desenvolvedor",
    },
    {
      name: "Mariana Antunes",
      comment:
        "Eu já queria mudar de carreira, justamente para colocar minha vida financeira em ordem. Quando a Kenzie apareceu com o modelo ISA de pagamento, foi a minha solução. Agora eu faço um curso de qualidade, e só pago ele, quando entrar no mercado, ganhando um bom salário",
      photo: "https://kenzie.com.br/images/carous5-img.png",
      job: "Desenvolvedora",
    },
  ];

  return (
    <Container>
      <h3>
        Veja o que acontece com quem se torna aluno da Kenzie e tem acesso à
        metodologia
      </h3>
      <Section>
        {commentsData.map((student, index) => (
          <Comment key={index} student={student} />
        ))}

        <img id="code-box1" src={codeBLur} alt="Código" />
        <img id="code-box2" src={codeBLur} alt="Código" />
        <img id="code-box3" src={codeBox} alt="Código" />
        <img id="code-box4" src={codeBox} alt="Código" />
        <img id="code-box5" src={codeBLur} alt="Código" />
      </Section>

      <ResultsWrapper>
        <ResultsContent>
          <p className="copy">
            Em apenas um ano, já ajudamos <span>centenas de alunos</span> a
            acelerarem sua entrada no mercado de tecnologia!
          </p>
          <div className="goals">
            <div className="goal">
              <p>600+</p>
              <span>Alunos matriculados</span>
            </div>
            <div className="goal">
              <p>80+</p>
              <span>Empresas parceiras</span>
            </div>
            <div className="goal">
              <p>98%</p>
              <span>De alunos empregados</span>
            </div>
          </div>
          <a
            href="https://resultados.kenzie.com.br"
            target="_blank"
            rel="noreferrer"
          >
            Conheça nossos Devs contratados <PlayIcon />
          </a>
        </ResultsContent>
        <div className="image-box">
          <img src={laptop} alt="Laptop" />
        </div>
      </ResultsWrapper>
    </Container>
  );
};

export default CommentsSection;
