import React from "react";

import ActiveCampaignForm from "../ActiveCampaignForm";

import metodo from "../../assets/metodo.png";
import conteudo from "../../assets/conteudo.png";
import suporte from "../../assets/suporte.png";
import parcerias from "../../assets/parcerias.png";
import projetos from "../../assets/projetos.png";
import softskills from "../../assets/softskills.png";
import vale from "../../assets/vale.png";

import { Container, Content, CardContainer, Card } from "./styles";

const InfoSection = () => {
  return (
    <Container>
      <Content>
        <h3>
          Na Kenzie temos o que é preciso para acelerar sua entrada no mercado
          de programação
        </h3>
        <CardContainer>
          <Card>
            <img src={metodo} alt="Checked Icon" />
            <p>
              <b>Método comprovado</b> por centenas de estudantes que, 
              em alguns meses, já estão no mercado de tecnologia.
            </p>
          </Card>
          <Card>
            <img src={vale} alt="Checked Icon" />
            <p>
              <b>Metodologia desenvolvida no Vale do Silício</b> pelos melhores
              engenheiros de tecnologia.
            </p>
          </Card>
          <Card>
            <img src={projetos} alt="Checked Icon" />
            <p>
              Aprendizado a partir de <b>projetos práticos</b> e em equipe. Uso
              de metodologias ágeis.
            </p>
          </Card>
          <Card>
            <img src={conteudo} alt="Checked Icon" />
            <p>
              <b>Conteúdo atualizado constantemente</b> para estar alinhado com
              o que o mercado pede.
            </p>
          </Card>
          <Card>
            <img src={suporte} alt="Checked Icon" />
            <p>
              <b>Suporte em tempo real</b> para tirar suas dúvidas diariamente
              com nosso time de ensino.
            </p>
          </Card>
          <Card>
            <img src={parcerias} alt="Checked Icon" />
            <p>
              <b>Parcerias com várias empresas do Brasil e do exterior</b> para
              acelerar sua contratação.
            </p>
          </Card>
          <Card>
            <img src={softskills} alt="Checked Icon" />
            <p>
              <b>Desenvolvimento de soft skills</b>, treinamentos e talks com
              especialistas de carreira.
            </p>
          </Card>
        </CardContainer>

        <ActiveCampaignForm formId />
      </Content>
    </Container>
  );
};

export default InfoSection;
