import styled from "styled-components";
import { FiArrowDown } from "react-icons/fi";

export const Container = styled.div`
  padding: 4rem 0;
  width: 80%;
  max-width: 1300px;
  margin: 0 auto;
  position: relative;

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 35rem;
    padding: 6rem 0;
  }

  img#code-blur {
    width: 5rem;
    display: block;
    position: absolute;
    top: 0rem;
    right: -1rem;

    @media (min-width: 1024px) {
      display: block;
      position: absolute;
      top: -1.5rem;
      right: -4rem;
      width: 7rem;
    }
  }

  img#code-box {
    width: 3rem;
    position: absolute;
    bottom: 0rem;
    left: 0rem;

    @media (min-width: 1024px) {
      display: block;
      z-index: 1000;

      width: 5rem;
      height: 5rem;
      bottom: -3.6rem;
    }
  }
`;

export const TextContent = styled.div`
  text-align: center;
  color: var(--white);
  width: 100%;
  max-width: 37.2rem;

  @media (min-width: 1024px) {
    text-align: left;
    word-spacing: 0.2rem;
    margin-right: 2rem;
  }

  h1 {
    font-size: 1.5rem;
    margin: 0;

    @media (min-width: 1024px) {
      font-size: 2.3rem;
      line-height: 2.8rem;
    }
  }

  p {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: normal;
    margin: 1rem 0;
    span {
      font-size: 1rem;
      color: #fe8967;
      display: inline-flex;
      align-items: center;
    }

    @media (min-width: 1024px) {
      font-size: 1.5rem;
      line-height: 2rem;
      margin: 2rem 0;
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  max-width: 25rem;
  padding: 1rem;
  border-radius: 2rem;
  background-color: var(--blue-500);
  color: var(--white);
  font-weight: bold;
  margin: 1rem auto;
  transition: filter 200ms;

  &:hover {
    filter: brightness(0.9);
  }

  @media (min-width: 1024px) {
    height: 4rem;

    font-size: 1.5rem;
  }
`;

export const VideoContent = styled.div`
  margin-top: 1rem;
  width: 100%;
  background-color: var(--blue-600);
  padding: 1rem;
  border-radius: 5px;
  text-align: center;
  position: relative;

  @media (min-width: 1024px) {
    height: 20rem;
    max-width: 38rem;
  }

  div.border-up,
  div.border-down {
    position: absolute;
    display: none;
  }

  div.border-up {
    position: absolute;
    width: 80%;
    top: -5rem;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid #fe8967;
    border-bottom: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-top: 1rem;

    @media (min-width: 1024px) {
      display: block;
    }

    p {
      color: var(--white);
      font-size: 1.2rem;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      span {
        color: #fe8967;
        display: inline-block;
        margin-left: 0.8rem;
      }

      @media (min-width: 1024px) {
        font-size: 1.5rem;
        font-weight: bold;
        margin: 1rem auto;
      }

      @media (max-width: 1300px) {
        font-size: 1.2rem;
        font-weight: bold;
        margin: 1rem auto;
      }
    }
  }

  div.border-down {
    position: absolute;
    width: 80%;
    height: 2rem;
    bottom: -3rem;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid #fe8967;
    border-top: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-bottom: 1rem;

    @media (min-width: 1024px) {
      font-size: 1.5rem;
      font-weight: bold;
      margin: 1rem auto;
      display: block;
    }
  }

  div#video,
  iframe {
    width: 100%;
    height: 100%;
  }
`;

export const ArrowIcon = styled(FiArrowDown)`
  margin-left: 1rem;
  color: #fff;
  font-size: 1.5rem;
`;
