import styled from "styled-components";
import bg from "../../assets/BgBlue.png";
export const Container = styled.div`
  width: 100%;
  position: relative;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
`;
