import Hashtag from "./hashtag";
import { HashSection } from "./styles";

const HashtagSection = () => {
  return (
    <HashSection>
      <Hashtag tag="NinguémMeSegura" color={"#fff"} />
      <Hashtag tag="VouSerDev " color={"#fff"} />
      <Hashtag tag="KenzieMade" color={"#fff"} />
    </HashSection>
  );
};

export default HashtagSection;
