import React from "react";

import { Container, Content } from "./styles";

const Comment = ({ student }) => {
  return (
    <Container>
      <img className="photo" src={student.photo} alt="Student" />
      <Content>
        <h4>
          {student.name} <span> |</span> <p className="job">{student.job}</p>
        </h4>
        <p>{student.comment}</p>
      </Content>
    </Container>
  );
};

export default Comment;
