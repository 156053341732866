import styled from "styled-components";
import { FaPlay } from "react-icons/fa";
import laptop from "../../assets/laptop.png";

export const Container = styled.div`
  padding: 1rem 0;
  width: 100%;
  margin: 0 auto;
  background-color: var(--gray-800);
  color: var(--white);
  text-align: center;

  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #122b48 0%, #0070fa 50%, #122b48 100%);
  }

  h3 {
    text-align: center;
    margin: 3rem auto;
    width: 80%;
    max-width: 42.875rem;
    font-size: 1.625rem;
    line-height: 2.5rem;

    @media (min-width: 1024px) {
      margin: 2rem auto;
      margin-top: 4rem;
      font-size: 2.625rem;
      line-height: 3.5rem;
      text-align: center;
    }
  }
`;

export const Section = styled.div`
  padding: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  img#code-box1,
  img#code-box2,
  img#code-box3,
  img#code-box4,
  img#code-box5 {
    display: none;
  }

  @media (min-width: 1024px) {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 4rem;

    img#code-box1 {
      position: absolute;
      display: block;
      right: 1rem;
      top: -1rem;
    }

    img#code-box2 {
      position: absolute;
      display: block;
      left: 0.5rem;
      top: 30rem;
      z-index: 1;
    }

    img#code-box3 {
      position: absolute;
      width: 7rem;
      height: 7rem;
      display: block;
      right: -5rem;
      top: 48rem;
      z-index: 1;
    }

    img#code-box4 {
      position: absolute;
      width: 7rem;
      height: 7rem;
      display: block;
      left: -5rem;
      top: 68rem;
      z-index: 1;
    }

    img#code-box5 {
      position: absolute;
      width: 7rem;
      height: 7rem;
      display: block;
      right: 2.5rem;
      top: 78rem;
      z-index: 1;
    }
  }
`;

export const ResultsWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--white);
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;
  color: var(--gray-700);

  @media (min-width: 1024px) {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  div.image-box {
    width: 100%;
    height: 100%;

    @media (min-width: 1024px) {
      background: url(${laptop});
      background-repeat: no-repeat;
      background-size: cover;
      max-height: 100%;
      padding-bottom: 1rem;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 0px;

      @media (min-width: 1024px) {
        object-fit: cover;
        display: none;
      }
    }
  }
`;

export const ResultsContent = styled.div`
  padding: 2rem;
  text-align: left;

  @media (min-width: 1024px) {
    padding: 3rem;
    padding-left: 5rem;
  }

  p.copy {
    font-size: 2.25rem;
    color: var(--gray-500);
    font-weight: bold;

    span {
      color: var(--blue-400);
    }
  }

  div.goals {
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (min-width: 1024px) {
      flex-direction: row;
    }
  }

  div.goal {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 0.9rem;
    box-shadow: 0px 3px 6px #00000045;
    margin-bottom: 1rem;

    @media (min-width: 1024px) {
      width: 11.25rem;
      margin-right: 2rem;
    }

    p {
      color: var(--blue-400);
      font-weight: bold;
      font-size: 2rem;
      margin: 0.5rem 0;

      @media (min-width: 1024px) {
        font-size: 3.325rem;
      }
    }

    span {
      color: var(--gray-400);
      font-size: 1.25rem;
    }
  }

  a {
    display: block;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;
    padding: 1rem 2rem;
    color: var(--white);
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 2rem;
    background: linear-gradient(90deg, #007afa 0%, #014995 100%);

    @media (min-width: 1024px) {
      width: 100%;
      max-width: 34.125rem;
      font-size: 1.5rem;
    }
  }
`;

export const PlayIcon = styled(FaPlay)`
  font-size: 1rem;
  margin-left: auto;
`;
