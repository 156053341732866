import { Container, PlayIcon } from "./styles";
import TagManager from "react-gtm-module";

const ActiveCampForm = ({ formId, order }) => {
  const handleClick = () => {
    TagManager.dataLayer({ dataLayer: { event: "button-click-vouserdev" } });

    window.location.href =
      "https://inscricaonoturno.kenzie.com.br" + window.location.search;
  };
  return (
    <Container order={order}>
      <button onClick={handleClick}>
        {order === "first"
          ? "Vou ser front"
          : "Vire um(a) dev front end em apenas 1 ano!"}{" "}
        <PlayIcon />
      </button>
    </Container>
  );
};

export default ActiveCampForm;
