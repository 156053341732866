import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 5rem;
  background-color: var(--gray-800);
  position: relative;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #122b48 0%, #0070fa 50%, #122b48 100%);
  }
`;

export const Content = styled.div`
  width: 80%;
  margin: 0 auto;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
