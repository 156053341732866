import styled from "styled-components";
import { FiCheckSquare, FiAward } from "react-icons/fi";

export const Container = styled.div`
  padding: 2rem 0;
  width: 100%;
  margin: 0 auto;
  background: linear-gradient(90deg, #001122 0%, #002b58 100%);
  color: var(--white);
  text-align: center;

  h3 {
    font-size: 1.625rem;
    line-height: 2.5rem;

    @media (min-width: 1024px) {
      margin: 2 auto;
      font-size: 2.625rem;
      line-height: 3.5rem;
      text-align: center;
    }
  }
`;

export const Content = styled.div`
  width: 80%;
  max-width: 1300px;
  margin: 0 auto;
  color: var(--white);
  text-align: center;
`;

export const MethodsTable = styled.table`
  margin: 2rem auto;
  width: 80%;
  border-collapse: collapse;

  @media (min-width: 1024px) {
    width: 80%;
    font-size: 1.2rem;
  }

  thead {
    background-color: var(--blue-500);
    font-size: 1.25rem;
  }

  thead tr {
    th.tridicional {
      border-top-right-radius: 5px;
      background-color: var(--red-500);

      @media (min-width: 1024px) {
        border-top-right-radius: 20px;
      }
    }

    th.kenzie {
      border-top-left-radius: 5px;
      background-color: var(--blue-400);

      @media (min-width: 1024px) {
        border-top-left-radius: 20px;
      }
    }
  }

  tbody {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    tr.dark {
      background-color: var(--gray-700);
    }

    tr.light {
      background-color: var(--gray-650);
    }
  }

  th,
  td {
    padding: 1rem 0.6rem;
    text-align: left;

    div {
      display: flex;
      align-items: center;
    }

    img {
      width: 1.1rem;
      margin-right: 1rem;
      display: none;

      @media (min-width: 1024px) {
        display: inline;
      }
    }

    p {
      margin: 0;
      display: inline;
    }

    @media (min-width: 1024px) {
      padding: 2rem;
      text-align: left;
    }
  }

  td.last-kenzie {
    border-bottom-left-radius: 5px;
    @media (min-width: 1024px) {
      border-bottom-left-radius: 20px;
    }
  }

  td.last-traditional {
    border-bottom-right-radius: 5px;
    @media (min-width: 1024px) {
      border-bottom-right-radius: 20px;
    }
  }
`;

export const TeamMessage = styled.div`
  width: 100%;
  max-width: 1300px;
  padding: 4rem 0;
  margin: 0 auto;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 4rem;
  border-bottom: 1px solid var(--gray-400);

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 60% 40%;
  }

  div.image-box {
    width: 100%;

    img {
      width: 100%;
    }
  }

  div.text-box {
    @media (min-width: 1024px) {
      height: 100%;
      padding-right: 3rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    h4 {
      font-size: 1.625rem;
      text-align: left;

      @media (min-width: 1024px) {
        font-size: 2.625rem;
        margin-bottom: 0;
      }
    }

    p {
      font-size: 1.2rem;
      line-height: 1.8rem;
      text-align: left;

      @media (min-width: 1024px) {
        font-size: 1.5rem;
        line-height: 2.5rem;
      }

      span {
        color: var(--blue-500);
      }
    }
  }
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const Card = styled.div`
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  border-radius: 5px;
  margin: 1rem 0;
  text-align: left;

  @media (min-width: 1024px) {
    width: 36rem;
    margin: 2rem;
    padding: 2rem;
    text-align: left;
    flex-direction: row;
  }

  img {
    width: 5rem;
    margin-right: 1rem;
  }

  h4 {
    margin: 1rem 0;
    font-size: 1.625rem;
    color: var(--white);
  }

  p {
    margin: 0 auto;
    margin-left: 0;
    font-size: 1.2rem;
    line-height: 2rem;
  }
`;

export const CheckIcon = styled(FiCheckSquare)`
  font-size: 3.5rem;
  color: var(--cyan-500);
`;

export const AwardIcon = styled(FiAward)`
  font-size: 3.5rem;
  color: var(--cyan-500);
`;
