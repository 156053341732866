import styled from "styled-components";

export const HashSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;

  margin: 0;
  background-color: var(--gray-800);
  padding: 2em 1em;
  box-sizing: border-box;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const HashStyle = styled.div`
  font-weight: 900;
  color: var(--white);

  display: flex;

  h2 {
    font-size: 1.25rem;
    margin: 0.5em 0;
  }

  @media (min-width: 1024px) {
    h2 {
      font-size: 1.5rem;
      margin: 1vw;
    }
  }
`;
