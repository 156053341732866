import React from "react";

import checked from "../../assets/icon_check.png";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import { Container, Section, HardSkillIcon, SoftSkillIcon } from "./styles";

const DropDown = () => {
  return (
    <Container>
      <h3>E como será o seu percurso de aprendizado?</h3>
      <Section>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span>
              <b>1°</b> Módulo
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <div className="module">
              <h4>
                <HardSkillIcon />
                Hard Skills
              </h4>
              <div className="module-section">
                <p>
                  <img src={checked} alt="" />
                  HTML5 e CSS3
                </p>
                <p>
                  <img src={checked} alt="" />
                  Web Semântica
                </p>
                <p>
                  <img src={checked} alt="" />
                  JavaScript (ES6)
                </p>
                <p>
                  <img src={checked} alt="" />
                  Lógica de programação
                </p>
                <p>
                  <img src={checked} alt="" />
                  Controle de versão com git
                </p>
                <p>
                  <img src={checked} alt="" />
                  JavaScript (ES6)
                </p>
                <p>
                  <img src={checked} alt="" />
                  Programação Orientada a Objetos
                </p>
                <p>
                  <img src={checked} alt="" />
                  Programação Funcional
                </p>
                <p>
                  <img src={checked} alt="" />
                  Qualidade
                </p>
                <p>
                  <img src={checked} alt="" />
                  TDD com Jest
                </p>
                <p>
                  <img src={checked} alt="" />
                  BDD com Cucumber
                </p>
              </div>
            </div>

            <div className="module">
              <h4>
                <SoftSkillIcon />
                Soft Skills
              </h4>
              <div className="module-section">
                <p>
                  <img src={checked} alt="" />
                  Aprender a aprender com os erros
                </p>
                <p>
                  <img src={checked} alt="" />
                  Gestão de tempo
                </p>
                <p>
                  <img src={checked} alt="" />
                  Formação de novos hábitos
                </p>
                <p>
                  <img src={checked} alt="" />
                  Comunicação clara e assertiva
                </p>
                <p>
                  <img src={checked} alt="" />
                  Treinamento para entrevista técnica
                </p>
                <p>
                  <img src={checked} alt="" />
                  Currículo e Linkedin (Básico)
                </p>
                <p>
                  <img src={checked} alt="" />
                  Autonomia
                </p>
                <p>
                  <img src={checked} alt="" />
                  Colaboração
                </p>
                <p>
                  <img src={checked} alt="" />
                  Feedback - como dar e receber
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <span>
              <b>2°</b> Módulo
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <div className="module">
              <h4>
                <HardSkillIcon />
                Hard Skills
              </h4>
              <div className="module-section">
                <p>
                  <img src={checked} alt="" />
                  React
                </p>
                <p>
                  <img src={checked} alt="" />
                  Hooks
                </p>
                <p>
                  <img src={checked} alt="" />
                  State Management
                </p>
                <p>
                  <img src={checked} alt="" />
                  Redux
                </p>
                <p>
                  <img src={checked} alt="" />
                  Componentização
                </p>
                <p>
                  <img src={checked} alt="" />
                  Uso de bibliotecas de componente
                </p>
                <p>
                  <img src={checked} alt="" />
                  Consumo de APIs
                </p>
                <p>
                  <img src={checked} alt="" />
                  Cypress
                </p>
                <p>
                  <img src={checked} alt="" />
                  Noções de UX
                </p>
              </div>
            </div>

            <div className="module">
              <h4>
                <SoftSkillIcon />
                Soft Skills
              </h4>
              <div className="module-section">
                <p>
                  <img src={checked} alt="" />
                  Aprender a aprender com os erros
                </p>
                <p>
                  <img src={checked} alt="" />
                  Produtividade
                </p>
                <p>
                  <img src={checked} alt="" />
                  Comunicação clara e assertiva
                </p>
                <p>
                  <img src={checked} alt="" />
                  Treinamento para entrevista técnica
                </p>
                <p>
                  <img src={checked} alt="" />
                  Currículo
                </p>
                <p>
                  <img src={checked} alt="" />
                  Autonomia
                </p>
                <p>
                  <img src={checked} alt="" />
                  Colaboração
                </p>
                <p>
                  <img src={checked} alt="" />
                  Feedback - como dar e receber
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <span>
              <b>3°</b> Módulo
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <div className="module">
              <h4>
                <HardSkillIcon />
                Hard Skills
              </h4>
              <div className="module-section">
                <p>
                  <img src={checked} alt="" />
                  Trilha de empregabilidade
                </p>
              </div>
            </div>

            <div className="module">
              <h4>
                <SoftSkillIcon />
                Soft Skills
              </h4>
              <div className="module-section">
                <p>
                  <img src={checked} alt="" />
                  Colaboração
                </p>
                <p>
                  <img src={checked} alt="" />
                  Comunicação clara e assertiva
                </p>
                <p>
                  <img src={checked} alt="" />
                  Construção de marca pessoal
                </p>
                <p>
                  <img src={checked} alt="" />
                  Construção de Portfolio
                </p>
                <p>
                  <img src={checked} alt="" />
                  Mapeamento de Competências
                </p>
                <p>
                  <img src={checked} alt="" />
                  Planejamento de Carreira
                </p>
                <p>
                  <img src={checked} alt="" />
                  Pitch Pessoal
                </p>
                <p>
                  <img src={checked} alt="" />
                  Simulações de Entrevista
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </Section>
    </Container>
  );
};

export default DropDown;
