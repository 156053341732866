import styled from "styled-components";

export const Container = styled.div`
  padding: 2rem 0;
  padding-bottom: 4rem;
  width: 100%;
  background-color: var(--gray-800);
`;

export const Content = styled.div`
  width: 80%;
  margin: 0 auto;
  background-color: var(--gray-800);
  color: var(--white);
  text-align: center;

  h3 {
    font-size: 1.625rem;
    line-height: 2.5rem;
    margin: 4rem 0;

    @media (min-width: 1024px) {
      font-size: 2.625rem;
      max-width: 65.625rem;
      margin: 4rem auto;
      line-height: 3.5rem;
      text-align: center;
    }
  }

  div.final-message {
    font-size: 1.625rem;
    line-height: 2.5rem;

    @media (min-width: 1024px) {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }
`;

export const CardContainer = styled.div`
  width: 100%;

  @media (min-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const Card = styled.div`
  width: 100%;
  background-color: var(--gray-700);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.35);
  border-radius: 0.9rem;
  padding: 1.5rem;
  text-align: left;
  margin-bottom: 2rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 200ms ease-in;

  &:hover {
    transform: scale(1.1);
  }

  @media (min-width: 1024px) {
    margin: 2rem;
    width: 16.25rem;
  }

  img {
    width: 4.6875rem;
    height: 4.6875rem;
  }

  p {
    font-size: 1.125rem;
    line-height: 1.5rem;

    b {
      color: var(--blue-400);
    }
  }
`;

export const B2B = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  background: linear-gradient(90deg, #001122 0%, #002b58 100%);
  border-radius: 0.9rem;
  padding: 2rem;

  @media (min-width: 1024px) {
    padding: 2rem 10rem;
  }

  p {
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 2rem;
    border-bottom: 1px solid var(--gray-600);

    @media (min-width: 1024px) {
      font-size: 3.25rem;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    img {
      width: 80%;
      height: 4.375rem;
      margin: 1rem auto;

      @media (min-width: 1024px) {
        width: 13.625rem;
      }
    }
  }
`;

export const MethodsTable = styled.table`
  margin: 2rem auto;
  border-collapse: collapse;

  @media (min-width: 1024px) {
    width: 80%;
    font-size: 1.2rem;
  }

  thead {
    background-color: var(--blue-500);
  }

  thead tr {
    th.tridicional {
      border-top-left-radius: 5px;
    }

    th.kenzie {
      border-top-right-radius: 5px;
    }
  }

  tbody {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid var(--gray-800);
  }

  th,
  td {
    padding: 1rem;
    text-align: center;

    @media (min-width: 1024px) {
      padding: 2rem;
      text-align: left;
    }
  }
`;
