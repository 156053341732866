import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  :root {
    --white: #fff;
    --blue-400: #007AFA;
    --blue-500: #007aff;
    --blue-600: #014995;
    --blue-700: #002B58;
    --blue-800: #00013c;
    --blue-900: #001122;
    --cyan-500: #00dbff;
    --red-500: #D94848;
    --black-400: #000007;
    --black-500: #000000;
    --gray-400: #686868;
    --gray-500: #656565;
    --gray-600: #535353;
    --gray-650: #262626;
    --gray-700: #1D1D1D;
    --gray-800: #191919;
    --gray-850: #141414;
    --gray-900: #121212;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%; //15px
    }

    @media (max-width: 720px) {
      font-size: 87.5%; //14px
    }
  }

  body {
    font-family: 'JetBrains Mono', monospace;
    -webkit-font-smoothing: antialiased;

    &, * {
      box-sizing: border-box;
    }

    ::-webkit-scrollbar {
      background-color: var(--gray-650);
    }

    ::-webkit-scrollbar-thumb {
      background: linear-gradient(0deg, #007AF9 0%, #014995 100%);
      border-radius: 0.25rem;
    }
  }

  button {
    cursor: pointer;
    border: none;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;

export default GlobalStyle;
