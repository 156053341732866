import styled from "styled-components";
import { FaPlay } from "react-icons/fa";

export const Container = styled.div`
  width: 100%;
  margin: 2rem auto;
  display: flex;
  justify-content: ${(props) =>
    props.order === "first" ? "flex-start" : "center"};

  button {
    width: 100%;
    max-width: ${(props) => (props.order === "first" ? "25rem" : "34.125rem")};
    padding: 1rem 2rem;
    border-radius: 3.125rem;
    background: linear-gradient(90deg, #007afa 0%, #014995 100%);
    color: var(--white);
    font-size: ${(props) => (props.order === "first" ? "2.25rem" : "1.2rem")};
    font-family: "JetBrains Mono", monospace;
    text-align: left;
    font-weight: bold;
    transition: all 200ms ease;

    display: flex;
    align-items: center;
    justify-content: space-between;

    transition: all 400ms ease-in;

    @media (min-width: 1024px) {
      min-height: 4rem;
      font-size: 1.5rem;
    }
  }
`;

export const PlayIcon = styled(FaPlay)`
  font-size: 1rem;
  margin-left: auto;
`;
