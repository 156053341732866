import React from "react";

import iconCheck from "../../assets/icon_check.png";
import iconRemove from "../../assets/icon_remove.png";
import kaGroup from "../../assets/ka_group.png";
import iconPig from "../../assets/icon-pig.png";
import iconPrize from "../../assets/icon-trofeu.png";

import {
  Container,
  Content,
  MethodsTable,
  TeamMessage,
  Cards,
  Card,
} from "./styles";

const Assurance = () => {
  return (
    <Container>
      <Content>
        <h3>Compare e veja porque a Kenzie é a sua melhor opção!</h3>

        <MethodsTable>
          <thead>
            <tr>
              <th className="kenzie">Método Kenzie</th>
              <th className="tridicional">Método Tradicional</th>
            </tr>
          </thead>
          <tbody>
            <tr className="dark">
              <td>
                <div>
                  {" "}
                  <img src={iconCheck} alt="" />
                  <p>12 meses</p>
                </div>
              </td>
              <td>
                <div>
                  {" "}
                  <img src={iconRemove} alt="" />
                  <p>4 ou 5 anos</p>
                </div>
              </td>
            </tr>
            <tr className="light">
              <td>
                {" "}
                <img src={iconCheck} alt="" />
                <p>Ensino MUITO mais prático</p>
              </td>
              <td>
                <div>
                  {" "}
                  <img src={iconRemove} alt="" />
                  <p>Ensino mais teórico</p>
                </div>
              </td>
            </tr>
            <tr className="dark">
              <td>
                <div>
                  {" "}
                  <img src={iconCheck} alt="" />
                  <p>
                    Desenvolvimento de soft skills e te damos suporte de
                    carreira
                  </p>
                </div>
              </td>
              <td>
                <div>
                  {" "}
                  <img src={iconRemove} alt="" />
                  <p>Não desenvolve soft skills</p>
                </div>
              </td>
            </tr>
            <tr className="light">
              <td>
                <div>
                  {" "}
                  <img src={iconCheck} alt="" />
                  <p>
                    Abrimos portas com empresas parceiras para sua contratação
                  </p>
                </div>
              </td>
              <td>
                <div>
                  {" "}
                  <img src={iconRemove} alt="" />
                  <p>
                    Você conclui o curso, sem garantia de empregabilidade e
                    sucesso
                  </p>
                </div>
              </td>
            </tr>
            <tr className="dark">
              <td>
                <div>
                  {" "}
                  <img src={iconCheck} alt="" />
                  <p>100% online, com aulas ao vivo</p>
                </div>
              </td>
              <td>
                <div>
                  {" "}
                  <img src={iconRemove} alt="" />
                  <p>EAD ou presencial</p>
                </div>
              </td>
            </tr>
            <tr className="light">
              <td>
                <div>
                  {" "}
                  <img src={iconCheck} alt="" />
                  <p>
                    Suporte personalizado, com dúvidas respondidas em até 5
                    minutos
                  </p>
                </div>
              </td>
              <td>
                <div>
                  {" "}
                  <img src={iconRemove} alt="" />
                  <p>Suporte padrão</p>
                </div>
              </td>
            </tr>
            <tr className="dark">
              <td className="last-kenzie">
                <div>
                  {" "}
                  <img src={iconCheck} alt="" />
                  <p>
                    Comunidade online no Slack para tirar dúvidas e networking
                  </p>
                </div>
              </td>
              <td className="last-traditional">
                <div>
                  {" "}
                  <img src={iconRemove} alt="" />
                  <p>Sem comunidade</p>
                </div>
              </td>
            </tr>
          </tbody>
        </MethodsTable>

        <TeamMessage>
          <div className="text-box">
            <h4>Nós só temos sucesso, Se você tiver sucesso!</h4>
            <p>
              Aqui na Kenzie, você não precisa pagar nada agora para começar a
              estudar -{" "}
              <span>
                Você estuda por 12 meses sem se preocupar com mensalidades.
              </span>{" "}
              Isso mesmo, nós oferecemos a possibilidade de você pagar o curso
              somente após o seu ingresso no mercado de trabalho. Quando a sua
              renda, após o curso, ultrapassar R$ 2.000 no mês, aí você retorna{" "}
              <span>apenas 17% dela para Kenzie</span>, por 60 meses ou até
              chegar a R$ 25 mil*, Se em 60 meses você não conseguiu a
              remuneração mínima de R$ 2 mil por mês, não é preciso pagar nada
              para a Kenzie.
            </p>
          </div>
          <div className="image-box">
            <img src={kaGroup} alt="Daniel" />
          </div>
        </TeamMessage>

        <Cards>
          <Card>
            <img src={iconPig} alt="Icone Porcos" />
            <div>
              <h4>Risco zero</h4>
              <p>
                O valor do 17% é só se você conseguir uma remuneração a partir
                de R$ 2.000 por mês, se a sua renda for inferior a esse valor,
                não precisa pagar nada pra Kenzie. Vou repetir, se você não
                tiver uma remuneração de, no mínimo R$ 2.000 por mês, não
                precisa pagar nada pra Kenzie!
              </p>
            </div>
          </Card>
          <Card>
            <img src={iconPrize} alt="Icone Troféu" />
            <div>
              <h4>Garantia de sucesso</h4>
              <p>
                Acreditamos tanto no potencial profissional que você vai se
                tornar com nosso método, que temos praticamente a certeza que se
                você se esforçar, se dedicar e levar a sério, a sua colocação no
                mercado será quase 100% garantida, e dessa maneira, você terá
                uma renda muito maior que R$ 2.000!
              </p>
            </div>
          </Card>
        </Cards>
      </Content>
    </Container>
  );
};

export default Assurance;
