import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  background-color: var(--gray-700);
  color: var(--white);
  box-shadow: 0px 4px 4px 6px rgba(0, 0, 0, 0.25);

  border-radius: 1.875rem;
  position: relative;
  padding: 2rem;
  margin-bottom: 2.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  z-index: 1000;

  @media (min-width: 1024px) {
    width: auto;
    margin: 1.5rem;
    padding: 4rem;
    flex-direction: row;
  }

  img.photo {
    border-radius: 50%;
  }
`;

export const Content = styled.div`
  margin: 0;

  @media (min-width: 1024px) {
    text-align: left;
    padding-left: 5rem;
  }

  h4 {
    margin: 2rem auto;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: bold;
    color: var(--blue-400);

    @media (min-width: 1024px) {
      margin: 2rem 0;
      text-align: left;
      font-size: 1.5rem;
      display: flex;
      align-items: center;
    }

    span {
      display: none;

      @media (min-width: 1024px) {
        display: inline;
        margin: 0 0.5rem;
      }
    }

    p.job {
      margin: 0.5rem 0;
      font-size: 1.25rem;
      font-weight: normal;
    }
  }

  p {
    font-size: 1.2rem;
    line-height: 1.75rem;

    @media (min-width: 1024px) {
      font-size: 1.25rem;
    }
  }
`;
