import styled from "styled-components";

export const Container = styled.div`
  width: 80%;
  height: 24.5rem;
  bottom: 1.2rem;
  transform: translateX(-50%);
  left: 50%;
  position: fixed;
  z-index: 1001;
  background-color: var(--white);
  border-radius: 0.5rem;
  box-shadow: none;
  box-shadow: 0px 3px 6px #00000045;
  display: ${(props) => (props.active ? "grid" : "none")};
  grid-template-rows: 15% 85%;
  animation: show 4s forwards;

  z-index: 1000;

  @keyframes show {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @media (min-width: 540px) {
    width: 23rem;
    transform: translateX(0%);
    left: 2rem;
  }

  header {
    width: 100%;
    padding: 1rem;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div.bar {
      width: 80%;
      height: 1rem;
      background-color: rgb(203, 229, 255);
      position: relative;
      border-radius: 0.1rem;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 98%;
        background: linear-gradient(
          90deg,
          rgb(0, 122, 250) 5%,
          rgb(1, 73, 149) 100%
        );
        border-radius: 0.1rem;
      }
    }

    span {
      color: rgb(1, 73, 149);
      font-weight: bold;
    }
  }

  main {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 0.5rem;
      object-fit: cover;
    }
  }

  button {
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: -1rem;
    right: -1rem;
    background-color: var(--gray-500);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    img {
      width: 1rem;
      height: 1rem;
    }
  }
`;
