import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: var(--black-500);
  color: var(--white);
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 0;
  box-sizing: border-box;
  border-top: 1px solid #2d2d2d;
  border-bottom: 1px solid #2d2d2d;

  div.copy {
    width: 100%;
    padding: 2rem 0;
    border-top: 1px solid #2d2d2d;
    color: #626262;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 1024px) {
    padding-top: 4rem;
  }
`;

export const Content = styled.div`
  width: 80%;
  max-width: 1200px;
  height: auto;
  margin: 0 auto;

  display: flex;
  align-items: flex-start;

  @media (min-width: 1024px) {
    justify-content: center;
  }

  img {
    width: 5rem;
    height: 5rem;
    margin-right: 2rem;

    @media (min-width: 1024px) {
      width: 10rem;
      height: 10rem;
      margin-right: 8rem;
    }
  }
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    padding-bottom: 2rem;
    flex-direction: row;
    justify-content: space-between;
  }

  ul {
    padding-left: 0;

    li + li {
      margin-top: 0.5rem;
    }

    @media (min-width: 1024px) {
      li + li {
        margin-top: 2rem;
      }
    }

    li {
      color: #626262;
      list-style: none;

      &.contact {
        color: var(--white);
        font-weight: bold;
        margin-top: 1rem;
      }

      a {
        text-decoration: none;
      }
    }
  }

  div.media,
  div.contact {
    margin: 0.5rem 0;

    @media (min-width: 1024px) {
      width: 50%;
    }

    p {
      margin-top: 0;
      font-weight: bold;
    }
  }
`;
