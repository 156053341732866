import React, { useState } from "react";

import woman from "../../assets/woman_98.png";
import close from "../../assets/close-pop-up.svg";

import { Container } from "./styles";

const PopUp = () => {
  const [popupState, setPopUpState] = useState(true);

  return (
    <Container active={popupState}>
      <header>
        <div className="bar"></div>
        <span>98%</span>
      </header>
      <main>
        <img src={woman} alt="" />
      </main>
      <button onClick={() => setPopUpState(false)}>
        <img src={close} alt="" />
      </button>
    </Container>
  );
};

export default PopUp;
