import React from "react";
import Header from "../../components/Header";
import FirstSection from "../../components/FirstSection";
import CommentsSection from "../../components/CommentsSection";
import InfoSection from "../../components/InfoSection";
import Assurance from "../../components/Assurance";
import CourseInformation from "../../components/CourseInformation";
import PaymentSection from "../../components/PaymentSection";
import HashTagSection from "../../components/HashTagSection";
import Footer from "../../components/Footer";
import PopUp from "../../components/PopUp";
import { Container } from "./styles";

const Home = () => {
  return (
    <Container>
      <Header />
      <FirstSection />
      <CommentsSection />
      <InfoSection />
      <CourseInformation />
      <Assurance />
      <PaymentSection />
      <HashTagSection />
      <Footer />
      <PopUp />
    </Container>
  );
};

export default Home;
