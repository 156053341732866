import React from "react";

import DropDown from "../DropDown";
// import DailyRoutineSection from "../DailyRoutineSection";

import madeiramadeira from "../../assets/madeira-madeira.png";
import boticario from "../../assets/grupo-boticario.png";
import picpay from "../../assets/picpay.png";
import ebanx from "../../assets/ebanx.png";
import james from "../../assets/james.png";
import mirum from "../../assets/mirum.png";
import Dani from "../../assets/Dani.png";

import { Container, Content, B2B, DanielMessage } from "./styles";

const CourseInformation = () => {
  return (
    <Container>
      <Content>
        <B2B>
          <p>Empresas que contratam alunos Kenzie</p>
          <div>
            <img src={madeiramadeira} alt="MadeiraMadeira" />
            <img src={boticario} alt="Grupo Boticário" />
            <img src={picpay} alt="PicPay" />
            <img src={ebanx} alt="Ebanx" />
            <img src={james} alt="James" />
            <img src={mirum} alt="Mirum" />
          </div>
        </B2B>

        <DanielMessage>
          <div className="image-box">
            <img src={Dani} alt="Daniel" />
            <p>
              Daniel Kriger | <span>CEO da Kenzie</span>
            </p>
          </div>
          <div className="text-box">
            <p>
              Nosso método foi desenvolvido por engenheiros do Vale do Silício
              (Califórnia - EUA) e já foi comprovado por centenas de estudantes
              que, em apenas alguns meses, concluíram seus estudos. Os alunos
              Kenzie desenvolveram hard e soft skills de forma profissional com
              nosso time de ensino e{" "}
              <span>
                já estão no mercado de tecnologia em 20% do tempo, ganhando 5x
                mais!
              </span>
            </p>
          </div>
        </DanielMessage>

        <DropDown />

        {/* <DailyRoutineSection /> */}
      </Content>
    </Container>
  );
};

export default CourseInformation;
