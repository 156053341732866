import React from "react";

import logoImg from "../../assets/newLogo.png";

import { Container, Content } from "./styles";

const Header = () => {
  return (
    <Container>
      <Content>
        <img src={logoImg} alt="Kenzie Academy" />
      </Content>
    </Container>
  );
};

export default Header;
