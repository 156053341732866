import React from "react";

import kenzieLogo from "../../assets/logo-footer.svg";

import { Container, Content, Links } from "./styles";

const Footer = () => {
  return (
    <Container>
      <Content>
        <img src={kenzieLogo} alt="Kenzie Academy" />

        <Links>
          <div className="media">
            <p>KENZIE NA REDE</p>

            <ul>
              <li>
                <a
                  href="https://www.facebook.com/kenzieacademybr/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/kenzieacademybr/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UC6rcCbDzhVoIm1V7WnwPDIQ?sub_confirmation=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  Youtube
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/school/kenzie-academy-brasil/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Linkedin
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/kenzie_br"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter
                </a>
              </li>
            </ul>
          </div>

          <div className="contact">
            <p>CONTATO</p>

            <ul>
              <li>
                R. General Mario Tourinho, 1733 - 706 - Seminário, Curitiba/PR
              </li>
              <li>
                R. Rio Javali, 361 - 69.053-110 - Nossa Senhora das Graças,
                Manaus/AM
              </li>
              <li>CNPJ 35.224.825/0001-74</li>
              <li className="contact">contato@kenzie.com.br</li>
            </ul>
          </div>
        </Links>
      </Content>

      <div className="copy">
        <p>&copy; 2021 Kenzie. All rights reserved.</p>
      </div>
    </Container>
  );
};

export default Footer;
