/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import codeBLur from "../../assets/code-blur.png";
import codeBox from "../../assets/code-box.png";
import ActiveCampaignForm from "../ActiveCampaignForm";

import { Container, TextContent, VideoContent, ArrowIcon } from "./styles";

const FirstSection = () => {
  return (
    <Container>
      <TextContent>
        <h1>
          Vire um(a) dev front end em 12 meses, pague apenas após a formatura e
          concorra às melhores vagas do mercado
        </h1>
        <p>
          Mesmo sem recurso para investir ou conhecimento prévio! Aprenda a ser
          front end com o método Kenzie
        </p>
        <div>
          <ActiveCampaignForm formId order="first" />
        </div>
      </TextContent>
      <VideoContent>
        <div className="border-up">
          <p>
            Aperte o play e <span>assista</span>! <ArrowIcon />
          </p>
        </div>
        <div id="video">
          <iframe
            src="https://www.youtube.com/embed/-q_dFH5okSg"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="border-down"></div>
      </VideoContent>

      <img id="code-blur" src={codeBLur} alt="Código" />
      <img id="code-box" src={codeBox} alt="Código" />
    </Container>
  );
};

export default FirstSection;
