import styled from "styled-components";

import { FaLaptopCode, FaGlobe } from "react-icons/fa";

export const Container = styled.div`
  padding: 2rem 0;
  width: 100%;
  margin: 0 auto;
  background-color: var(--black-500);
  color: var(--white);
  text-align: center;

  h3 {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 3rem;
    font-size: 1.625rem;
    line-height: 2.5rem;

    @media (min-width: 1024px) {
      margin: 2rem auto;
      font-size: 2.625rem;
      line-height: 3.5rem;
      text-align: center;
    }
  }
`;

export const Section = styled.div`
  width: 100%;
  margin: 2rem auto;
  background-color: var(--black-500);

  @media (min-width: 1024px) {
    width: 80%;
  }

  div {
    background-color: transparent;
    color: white;
    font-size: 1.5rem;
    text-align: left;
  }

  div.MuiAccordionSummary-root {
    background-color: var(--gray-800);
    border-radius: 0.5rem;
  }

  div.MuiAccordionSummary-content {
    font-size: 1.2rem;
    padding: 0.1rem 0.5rem;
    font-weight: bold;

    span {
      display: flex;
      align-items: center;

      b {
        margin-right: 5px;
      }
    }
  }

  div.MuiAccordion-rounded {
    border: 2px solid var(--gray-800);
    border-radius: 0.5rem;
    margin: 2rem 0;

    div.MuiAccordionDetails-root {
      display: flex;
      flex-direction: column;
      /* background-color: var(--gray-800); */

      @media (min-width: 1024px) {
        flex-direction: row;
        justify-content: space-between;
        padding: 2rem;
      }

      div.module {
        h4 {
          font-size: 1.2rem;
          display: flex;
          align-items: center;

          @media (min-width: 1024px) {
            font-size: 2rem;
          }
        }

        div.module-section {
          display: flex;
          flex-direction: column;
          font-size: 1.2rem;

          p {
            display: flex;
            align-items: center;

            @media (min-width: 1024px) {
              font-size: 1.5rem;
            }

            img {
              width: 1.5rem;
              margin-right: 0.5rem;

              @media (min-width: 1024px) {
                width: 2.5rem;
                margin-right: 1rem;
              }
            }
          }
        }
      }
    }
  }
`;

export const HardSkillIcon = styled(FaLaptopCode)`
  font-size: 2.5rem;
  color: var(--blue-500);
  margin-right: 0.5rem;
`;

export const SoftSkillIcon = styled(FaGlobe)`
  font-size: 2.5rem;
  color: var(--blue-500);
  margin-right: 0.5rem;
`;
